<template>
  <div class="v-stack h-stretch gap-3" v-if="project">
    <div class="overlay" ref="overlay">
      <div class="card">
        <div class="content h-stretch">
          <EditStaff
            :staff="selectedStaff"
            :role="selectedRole"
            v-on:dismiss="hideEditDialog()"
          ></EditStaff>
        </div>
      </div>
    </div>
    <div class="panel-right h-stretch v-start">
      <input type="text" placeholder="Type in to search..." v-model="search" />
      <div class="flex-container">
        <button
          class="tag"
          v-for="role in preProductionRoles
            .concat(postProductionRoles)
            .concat(productionRoles)"
          :key="role.name"
          @click="selectTag(role.name)"
          v-bind:class="[role.name == selectedTag ? 'selected' : '']"
        >
          {{ role.name }}
        </button>
      </div>
      <a class="heading-title-2">Crew</a>
      <div class="flex-container">
        <div v-for="user in crew" :key="user._id" class="text-left">
          <div
            :draggable="true"
            @dragstart="onDrag($event, user)"
            class="crew-icon"
          >
            <div class="initials">
              {{ user.firstName[0] + user.lastName[0] }}
            </div>
            <div class="tooltip">
              {{ user.fullName }}
            </div>
          </div>
        </div>
      </div>
      <a class="heading-title-2">Freelancers</a>
      <div class="flex-container">
        <div v-for="user in freelancers" :key="user._id" class="text-left">
          <div
            :draggable="true"
            @dragstart="onDrag($event, user)"
            class="crew-icon"
          >
            <div class="initials">
              {{ user.firstName[0] + user.lastName[0] }}
            </div>
            <div class="tooltip">
              {{ user.fullName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="v-stack h-stretch gap-3 v-start">
      <div class="pane-horizontal gap-3">
        <div class="v-stack h-stretch v-start gap-3">
          <a class="heading-title-2">Pre Production</a>
          <div class="card light ma-0">
            <div v-if="preProductionRoles.length == 0" class="empty-message">
              No Roles in Budget
            </div>
            <div class="content h-stretch">
              <div class="v-stack gap-3 h-stretch">
                <div
                  class="v-stack h-stretch"
                  v-for="role in preProductionRoles"
                  :key="role.name"
                >
                  <div class="oneline" @click="selectTag(role.name)">
                    {{ role.name }}
                  </div>
                  <div
                    @dragenter.prevent="onDragEnter(role)"
                    @dragover.prevent="onDragEnter(role)"
                    @dragleave.prevent="onDragLeave(role)"
                    @drop.prevent="onDrop($event, role, 'pre')"
                    class="flex-container"
                    style="min-height: 64px"
                  >
                    <div
                      v-for="staff in project.preproductionStaff.filter(
                        (staff) => staff.roles.includes(role.name)
                      )"
                      :key="staff._id"
                      class="crew-icon"
                      @click="showEditDialog(staff, role.name)"
                    >
                      <div class="initials">
                        {{ staff.user.firstName[0] + staff.user.lastName[0] }}
                      </div>
                      <div class="tooltip">
                        {{ staff.user.fullName }}
                      </div>
                    </div>
                    <div v-if="role.dummy" class="crew-icon dummy"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="v-stack h-stretch v-start gap-3">
          <a class="heading-title-2">Post Production</a>
          <div class="card light ma-0">
            <div v-if="postProductionRoles.length == 0" class="empty-message">
              No Roles in Budget
            </div>
            <div class="content h-stretch">
              <div class="v-stack gap-3 h-stretch">
                <div
                  class="v-stack h-stretch"
                  v-for="role in postProductionRoles"
                  :key="role.name"
                >
                  <div class="oneline" @click="selectTag(role.name)">
                    {{ role.name }}
                  </div>
                  <div
                    @dragover.prevent
                    @dragenter="onDragEnter(role)"
                    @dragover="onDragEnter(role)"
                    @dragleave="onDragLeave(role)"
                    @drop.prevent="onDrop($event, role, 'post')"
                    class="flex-container"
                    style="min-height: 64px"
                  >
                    <div
                      v-for="staff in project.postproductionStaff.filter(
                        (staff) => staff.roles.includes(role.name)
                      )"
                      :key="staff._id"
                      class="crew-icon"
                      @click="showEditDialog(staff, role.name)"
                    >
                      <div class="initials">
                        {{ staff.user.firstName[0] + staff.user.lastName[0] }}
                      </div>
                      <div class="tooltip">
                        {{ staff.user.fullName }}
                      </div>
                    </div>
                    <div v-if="role.dummy" class="crew-icon dummy"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-stack h-space-between">
      <a class="heading-title-2">Production</a>
      <button
        class="add"
        @click="$router.push('/projects/production/' + project._id)"
      >
        +
      </button>
    </div>
    <div class="card light mn" v-if="project.recordingDays.length == 0">
      <div class="content">
        <div>Click the + button to add a new production day.</div>
      </div>
    </div>
    <div
      class="card light float mn"
      v-for="day in project.recordingDays"
      :key="day._id"
    >
      <button
        class="edit float"
        @click="$router.push('/productiondays/edit/' + day._id)"
      >
        Edit
      </button>
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <div class="h-stack gap-3 h-start">
            <label class="text-right" style="width: 100px">Date:</label>
            <div class="text-left">
              {{ formatDate(day.startDate, "D. M. YYYY") }} |
              {{ formatDate(day.startDate, "HH:mm") }} -
              {{ formatDate(day.endDate, "HH:mm") }}
            </div>
          </div>
          <div class="h-stack gap-3 h-start">
            <label class="text-right" style="width: 100px">Address:</label>
            <div class="text-left">{{ day.address }}</div>
          </div>
          <div class="h-stack gap-3 h-start">
            <label class="text-right" style="width: 100px">Description:</label>
            <div class="text-left">{{ day.description }}</div>
          </div>
          <div class="pane-left gap-3 h-stretch v-start">
            <label class="text-right" style="width: 100px">Roles:</label>
            <div class="card mn" style="justify-self: stretch">
              <div class="content h-stretch">
                <div v-if="productionRoles.length == 0" class="empty-message">
                  No Roles in Budget
                </div>
                <div class="content h-stretch">
                  <div class="v-stack gap-3 h-stretch">
                    <div
                      class="v-stack h-stretch"
                      v-for="role in productionRoles"
                      :key="role.name"
                    >
                      <div class="oneline" @click="selectTag(role.name)">
                        {{ role.name }}
                      </div>
                      <div
                        @dragenter.prevent="onDragEnter(role)"
                        @dragover.prevent="onDragEnter(role, day._id)"
                        @dragleave.prevent="onDragLeave(role)"
                        @drop.prevent="onDrop($event, role, 'pro', day)"
                        class="flex-container"
                        style="min-height: 64px"
                      >
                        <div
                          v-for="staff in day.staff.filter((staff) =>
                            staff.roles.includes(role.name)
                          )"
                          :key="staff._id"
                          class="crew-icon"
                          @click="showEditDialog(staff, role.name)"
                        >
                          <div class="initials">
                            {{
                              staff.user.firstName[0] + staff.user.lastName[0]
                            }}
                          </div>
                          <div class="tooltip">
                            {{ staff.user.fullName }}
                          </div>
                        </div>
                        <div
                          v-if="role.dummy && role._id == day._id"
                          class="crew-icon dummy"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Fuse from "fuse.js";
import EditStaff from "@/views/projects/EditStaff.vue";
import moment from "moment";

export default {
  data() {
    return {
      productionRoles: [],
      preProductionRoles: [],
      postProductionRoles: [],
      users: [],
      selectedTag: null,
      search: "",
      selectedStaff: null,
      selectedRole: null,
    };
  },
  components: {
    EditStaff,
  },
  computed: {
    crew() {
      let crew = this.users.filter((user) => user.accessLevel > 4);

      if (this.selectedTag) {
        crew = crew.filter((user) =>
          user.categories.some((category) => category.name == this.selectedTag)
        );
      }

      const query = this.search.trim();
      if (query.length > 0) {
        const options = {
          keys: ["categories.name", "fullName"],
          threshold: 0.3,
          ignoreLocation: true,
        };
        const fuse = new Fuse(crew, options);
        return fuse.search(query).map((resultItem) => resultItem.item);
      }

      return crew;
    },
    freelancers() {
      let freelancers = this.users.filter((user) => user.accessLevel == 0);

      if (this.selectedTag) {
        freelancers = freelancers.filter((user) =>
          user.categories.some((category) => category.name == this.selectedTag)
        );
      }

      const query = this.search.trim();
      if (query.length > 0) {
        const options = {
          keys: ["categories.name", "fullName"],
          threshold: 0.3,
          ignoreLocation: true,
        };
        const fuse = new Fuse(freelancers, options);
        return fuse.search(query).map((resultItem) => resultItem.item);
      }

      return freelancers;
    },
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions([
      "getUsers",
      "addStaffPreProduction",
      "addStaffPostProduction",
      "addStaffProduction",
      "editStaff",
    ]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    showEditDialog(staff, role) {
      this.selectedRole = role;
      this.selectedStaff = staff;
      this.$refs.overlay.classList.add("show");
    },
    hideEditDialog() {
      this.$refs.overlay.classList.remove("show");
      this.$emit("refresh");
    },
    selectTag(role) {
      if (this.selectedTag == role) {
        this.selectedTag = null;
      } else {
        this.selectedTag = role;
      }
    },
    onDrag(event, user) {
      event.dataTransfer.setData("user", user._id);
    },
    onDrop(event, role, type, day = null) {
      role.dummy = false;

      const userId = event.dataTransfer.getData("user");
      const user = this.getUserFromId(userId);

      if (type == "pro") {
        for (const staff of day.staff) {
          if (staff.user._id == user._id) {
            if (!staff.roles.includes(role.name)) {
              staff.roles.push(role.name);
              this.editStaff(staff)
                .then(() => {
                  this.$emit("refresh");
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            return;
          }
        }

        const staff = {
          user: user,
          productionDay: day._id,
          project: this.project._id,
          roles: [role.name],
        };

        day.staff.push(staff);

        this.addStaffProduction(staff)
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (type == "post") {
        for (const staff of this.project.postproductionStaff) {
          if (staff.user._id == user._id) {
            if (!staff.roles.includes(role.name)) {
              staff.roles.push(role.name);
              this.editStaff(staff)
                .then(() => {
                  this.$emit("refresh");
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            return;
          }
        }

        const staff = {
          user: user,
          project: this.project._id,
          roles: [role.name],
        };

        this.project.postproductionStaff.push(staff);

        this.addStaffPostProduction(staff)
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (type == "pre") {
        for (const staff of this.project.preproductionStaff) {
          if (staff.user._id == user._id) {
            if (!staff.roles.includes(role.name)) {
              staff.roles.push(role.name);
              this.editStaff(staff)
                .then(() => {
                  this.$emit("refresh");
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            return;
          }
        }

        const staff = {
          user: user,
          project: this.project._id,
          roles: [role.name],
        };

        this.project.preproductionStaff.push(staff);

        this.addStaffPreProduction(staff)
          .then(() => {
            this.$emit("refresh");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onDragEnter(role, id = null) {
      role.dummy = true;
      role._id = id;
      this.$forceUpdate();
    },
    onDragLeave(role) {
      role.dummy = false;
    },
    getUserFromId(id) {
      for (const user of this.users) {
        if (id == user._id) {
          return user;
        }
      }
      return null;
    },
  },
  mounted() {
    this.getUsers()
      .then((users) => {
        this.users = users;
      })
      .catch((error) => {
        console.log(error);
      });

    let items = [];
    for (const pricelistID of this.project.selectedPricelists) {
      for (const pricelist of this.project.pricelists) {
        if (pricelistID == pricelist._id) {
          items.push(pricelist);
        }
      }
    }

    items = items.map((pricelist) => pricelist.items);
    items = [].concat.apply([], items).map((item) => item.item);

    this.productionRoles = Array.from(
      new Set(
        items
          .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
          .filter((item) => item.categorySub == "Produkce")
          .map((item) => item.name)
      )
    ).map((item) => {
      return {
        name: item,
        dummy: false,
      };
    });

    this.preProductionRoles = Array.from(
      new Set(
        items
          .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
          .filter((item) => item.categorySub == "Preprodukce")
          .map((item) => item.name)
      )
    ).map((item) => {
      return {
        name: item,
        dummy: false,
      };
    });

    this.postProductionRoles = Array.from(
      new Set(
        items
          .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
          .filter((item) => item.categorySub == "Postprodukce")
          .map((item) => item.name)
      )
    ).map((item) => {
      return {
        name: item,
        dummy: false,
      };
    });
  },
};
</script>

<style lang="scss" scoped>
.role-row {
  height: 60px;
}

.oneline {
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  user-select: none;
}

.oneline:hover {
  text-decoration: underline;
}

.crew-icon {
  margin: 6px;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  border: 1px solid grey;
  background-color: white;
}

.crew-icon.dummy {
  background-color: grey;
  opacity: 0.1;
}

.crew-icon:hover {
  background-color: grey;
  cursor: pointer;
}

.crew-icon > .initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.crew-icon > .tooltip {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: grey;
  color: white;
  border-radius: 6px;
  padding: 6px;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.crew-icon .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid grey 10px;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: -10px;
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
}

.crew-icon:hover > .tooltip {
  opacity: 1;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card {
  position: relative;
  min-height: 60px;
}

.card > .empty-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.card.float {
  position: relative;
}

.edit.float {
  position: absolute;
  top: 12px;
  right: 12px;
}

@media (max-width: 1200px) {
  .pane-horizontal {
    grid-auto-flow: row;
    grid-template-columns: unset;
  }
}

button.tag {
  line-height: unset;
  box-shadow: unset;
  width: unset;
  height: unset;
  margin: 6px;
  padding: 6px;
  font-size: 12px;
  max-width: 120px;
  background-color: white;
  border: solid 1px grey;
  color: black;
}

button.tag.selected {
  background-color: grey;
  color: white;
}

.overlay > .card {
  width: 80vw;
  max-width: 600px;
}
</style>
